import { useContext } from "react";
import UserContext from "../components/UserContext";
import useCollectionSubscribe from "./useCollectionSubscribe";
import { stringValueExists } from "@/methods/utilities";
import { mergeProfiles } from "@/methods/profiles";

export const PROFILES_SOURCE = "Profiles";
export const ORG_DATA_SOURCE = "PersonalData";

export default function useAllProfiles({ orgId, justProfiles = false, noProfiles = false }) {
  const { user } = useContext(UserContext);

  const orgWhere = orgId ? [["managedBy", "==", orgId]] : [];

  // hack for this to be of length 0 when justProfiles is true
  const removePersonalData = justProfiles ? [["notHere", "==", "1234!!"]] : [];

  const [personalData, personalDataLoading, personalDataError] =
    useCollectionSubscribe({
      path: "PersonalData",
      where: [["User", "==", user?.uid], ...orgWhere, ...removePersonalData],
    });

  const [newProfiles, profilesLoading, error] = useCollectionSubscribe({
    path: `User/${user?.uid}/Profiles`,
    where: [],
  });

  // use the _id from the personal data doc, but the data from the profile doc

  // TODO: change matching to use userProfileId set in the profile doc, instead of name
  const matches = (a, b) =>
    a?.["Full Name"]?.[0] === b?.["Full Name"]?.[0] ||
    (a?.userProfileId && a.userProfileId === b?._id) ||
    (b?.userProfileId && b.userProfileId === a?._id);

  const generateMergedProfiles = () => {
    const mergedProfiles = [];
    if (personalData?.length > 0) {
      personalData.forEach((legacyProfile) => {
        const newProfile = newProfiles?.find((item) =>
          matches(item, legacyProfile)
        );
        if (newProfile) {
          mergedProfiles.push({
            ...mergeProfiles(legacyProfile, newProfile),
            _id: legacyProfile._id,
            source: ORG_DATA_SOURCE,
          });
        } else {
          mergedProfiles.push({ ...legacyProfile, source: ORG_DATA_SOURCE });
        }
      });
    }
    // Believe we only add a user profile if we cannot find a match for it in the personal data docs of this organisation
    if (newProfiles?.length > 0 && !noProfiles) {
      // add new profile if it's not in the merged profiles
      newProfiles.forEach((newProfile) => {
        if (!mergedProfiles.find((item) => matches(item, newProfile))) {
          // Strip forbidden keys off of the User Profile object if they're present
          // so we don't end up polluting data for other organisations
          const { tags, managedBy, funderMemberId, source, sourceId, userProfileId, ...restOfProfile } = newProfile;
          mergedProfiles.push({ ...restOfProfile, source: PROFILES_SOURCE });
        }
      });
    }

    return mergedProfiles;
  };

  const profiles = generateMergedProfiles();

  return [profiles, (personalDataLoading || profilesLoading) && stringValueExists(user?.uid)];
}
