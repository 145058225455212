/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { XMarkIcon } from "@heroicons/react/24/solid";
import useAllProfiles from "../hooks/useAllProfiles";
import ExtraDetails from "./ExtraDetails";
import SelectOrAddProfile from "./SelectOrAddProfile";

export const generateDataFromId = (profiles, id) => {
  const profile = profiles?.find((item) => item._id === id);
  if (!profile) return {};
  const { source, managedBy, ...data } = profile;
  if (source === "Profiles") {
    return { ...data, sourceId: profile._id, _id: null };
  }
  return profile;
};

const ChooseAndUpdateProfile = (props) => {
  const { onFinish, existingDetails, onSelect, noAutoSelect, ...rest } = props;

  const [profileId, setProfileId] = useState(null);

  const [profiles] = useAllProfiles({ orgId: props.orgId });

  useEffect(() => {
    if (!profileId && profiles?.length > 0 && !noAutoSelect) {
      setProfileId(profiles[0]._id);
    }
  }, [profiles?.length, noAutoSelect]);

  useEffect(() => {
    if (onSelect) {
      onSelect(profileId);
    }
  }, [profileId]);

  const [hideShowCopy, setHideShowCopy] = useState(false);

  const showCopyAcross =
    profiles.length > 0 && profileId === null && !hideShowCopy;

  const [copiedDetails, setCopiedDetails] = useState({});
  const [copyTarget, setCopyTarget] = useState(null);

  const handleChangeCopyTarget = (e) => {
    setCopyTarget(e.target.value);
  };

  const handleCopy = () => {
    // Strip out any keys on the object which will fuck things up data wise
    const {
      _id,
      sourceId,
      managedBy,
      tags,
      funderMemberId,
      userProfileId,
      source,
      ...relevantData
    } = generateDataFromId(profiles, copyTarget || profiles?.[0]?._id || null);

    delete relevantData["Full Name"];

    // Remove DOB
    const { dateOfBirth, ...demoRest } = relevantData?.DEMOGRAPHICS || {};

    const newDetails = {
      ...relevantData,
      DEMOGRAPHICS: { ...demoRest },
    };

    setCopiedDetails(newDetails);
    setHideShowCopy(true);
  };

  return (
    <div>
      {showCopyAcross ? (
        <div>
          <button
            type="button"
            onClick={() => setProfileId(profiles[0]._id)}
            className="btn hover:shadow-lg flex items-center border border-gray-300 rounded-full py-1 px-2"
          >
            <XMarkIcon className="text-gray-700 h-5 w-5 mr-2" />
            Cancel
          </button>
          <div className="bg-white text-sm inline-flex flex-col border-gray-300  border my-2 p-4 rounded-lg space-y-4">
            <div className="flex items-center space-x-4">
              <div className="text-base font-medium">
                Do you want to copy details from another profile?
              </div>
            </div>
            <div className="flex items-stretch w-full">
              <select
                onChange={handleChangeCopyTarget}
                className="rounded-l-lg text-sm flex-1"
              >
                {profiles.map((profile) => (
                  <option value={profile._id}>
                    {profile?.["Full Name"]?.[0]}
                  </option>
                ))}
              </select>

              <button
                type="button"
                onClick={handleCopy}
                className="btn btn-primary border-t-primary border-r-primary border-b-primary rounded-l-none rounded-r-lg  px-4"
              >
                Copy
              </button>
            </div>
            <div className="relative flex py-2 items-center">
              <div className="flex-grow border-t border-gray-400" />
              <span className="flex-shrink mx-4 text-gray-400">Or</span>
              <div className="flex-grow border-t border-gray-400" />
            </div>
            <button
              type="button"
              onClick={() => setHideShowCopy(true)}
              className="btn btn-primary border-primary rounded-r-lg py-2 px-4"
            >
              Start from scratch
            </button>
          </div>
        </div>
      ) : (
        <>
          {profiles.length > 0 && (
            <SelectOrAddProfile
              color={props.color}
              profiles={profiles || []}
              selected={profileId}
              onSelect={(id) => setProfileId(id)}
              handleAdd={() => {
                setProfileId(null);
                setHideShowCopy(false);
              }}
            />
          )}
          <ExtraDetails
            {...rest}
            onFinish={onFinish}
            existingDetails={{
              ...(profileId
                ? generateDataFromId(profiles, profileId)
                : copiedDetails),
              ...existingDetails,
            }}
          />
        </>
      )}
    </div>
  );
};

ChooseAndUpdateProfile.propTypes = {
  orgId: PropTypes.string.isRequired,
  hideSave: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  eventData: PropTypes.object,
  color: PropTypes.string,
  existingDetails: PropTypes.object,
  excludeFieldIds: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  noMaxHeight: PropTypes.bool,
  noAutoSelect: PropTypes.bool,
  onFinish: PropTypes.func,
};

ChooseAndUpdateProfile.defaultProps = {
  hideSave: false,
  eventData: {},
  color: null,
  existingDetails: {},
  excludeFieldIds: [],
  loading: false,
  noMaxHeight: false,
  noAutoSelect: false,
  onFinish: () => {},
};

export default ChooseAndUpdateProfile;
